import './Experience.css'
import { useEffect, useRef } from "react";
import { useSpring, animated } from '@react-spring/web'

export default function Experience() {

  const [titleSprings, titleApi] = useSpring(() => ({
    from: { x: -200, opacity: 0, scale: 3 }
  }))

  const [oneSprings, oneApi] = useSpring(() => ({
    from: { x: -200 },
  }));

  const [twoSprings, twoApi] = useSpring(() => ({
    from: { x: 200 },
  }));
  
  const [threeSprings, threeApi] = useSpring(() => ({
    from: { x: -200 },
  }));

  const animateTitle = useRef(null);
  const animateOne = useRef(null);
  const animateTwo = useRef(null);
  const animateThree = useRef(null);

  useEffect(() => {
    function animate(element, animation) {
      const options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.01,
        once: true
      };
  
      const observer = new IntersectionObserver((entries) => {
        const target = entries[0];
        if (target.isIntersecting) {
          animation();
        }
      }, options);
  
      if (element.current) {
        observer.observe(element.current);
      }
    }
    animate(animateTitle, titleSlide);
    animate(animateOne, oneSlide);
    animate(animateTwo, twoSlide);
    animate(animateThree, threeSlide);
  });

  const titleSlide = () => {
    titleApi.start({
      from: {
        x: -200,
        opacity: 0,
        scale: 3
      },
      to: {
        x: 0,
        opacity: 1,
        scale: 1
      },
    });
  }

  const oneSlide = () => {
    oneApi.start({
      from: {
        x: -200
      },
      to: {
        x: 0
      },
    });
  }

  const twoSlide = () => {
    twoApi.start({
      from: {
        x: 200
      },
      to: {
        x: 0
      },
    });
  }

  const threeSlide = () => {
    threeApi.start({
      from: {
        x: -200
      },
      to: {
        x: 0
      },
    });
  }


  return (
    <>
      <main className='main-bg' id='experience'>
        <section className="experience-section">
          <animated.h1 className="experience-title" ref={animateTitle} style={{...titleSprings}}>My Experience</animated.h1>
          <ul className="experience-list">
            <animated.li className="experience-li" ref={animateOne} style={{...oneSprings}}>
              <h3 className="company-name">General Assembly</h3>
              <h4 className="position">Software Engineering Immersive</h4>
              <h4 className="date">April 2023 - June 2023</h4>
              <ul className="description">
                <li>A 3 month immersive software engineering course.</li>
                <li>Worked with a variety of languages and frameworks including, JavaScript, Node.js, Python, React, Express and Django.</li>
                <li>Regularly used both SQL and NoSQL databases.</li>
                <li>Built projects collaboratively with other developers.</li>
                <li>Used Git for version control and collaboration.</li>
              </ul>
            </animated.li>
            <animated.li className="experience-li" ref={animateTwo} style={{...twoSprings}}>
              <h3 className="company-name">Louise Sinclair Jewellery</h3>
              <h4 className="position">Operations Assistant</h4>
              <h4 className="date">August 2022 - April 2023</h4>
              <ul className="description">
                <li>Spearheaded the CCR improvement ideas via the implementation of improved customer payment methods such as Klarna and PayPal Credit. </li>
                <li>Regularly attended company events and promoted the brand to high net-worth potential clients.</li>
                <li>Assisted in order processing, order tracking and customer inquiries. Found workarounds for maintaining order shipments throughout postal strikes.</li>
              </ul>
            </animated.li>
            <animated.li className="experience-li" ref={animateThree} style={{...threeSprings}}>
              <h3 className="company-name">Rose & Crown Public House</h3>
              <h4 className="position">Front of House</h4>
              <h4 className="date">March 2017 - August 2017</h4>
              <ul className="description">
                <li>Gained proficiency in customer service and relations.</li>
                <li>Collaborated with colleagues to ensure customer demands were met, while under pressure during busy periods.</li>
                <li>Acquired in-depth knowledge about the business' product offerings.</li>
              </ul>
            </animated.li>
          </ul>
        </section>
      </main>
    </>
  )
}