import './Skills.css'
import { useEffect, useRef } from "react";
import { useSpring, animated } from '@react-spring/web'




export default function Skills() {

  const [titleSprings, titleApi] = useSpring(() => ({
    from: { x: 300 },
  }));
  const [jsSpring, jsApi] = useSpring(() => ({
    from: { opacity: 0 },
  }));
  const [htmlSpring, htmlApi] = useSpring(() => ({
    from: { opacity: 0 },
  }));
  const [cssSpring, cssApi] = useSpring(() => ({
    from: { opacity: 0 },
  }));
  const [pythonSpring, pythonApi] = useSpring(() => ({
    from: { opacity: 0 },
  }));
  const [nodeSpring, nodeApi] = useSpring(() => ({
    from: { opacity: 0 },
  }));
  const [gitSpring, gitApi] = useSpring(() => ({
    from: { opacity: 0 },
  }));
  const [reactSpring, reactApi] = useSpring(() => ({
    from: { opacity: 0 },
  }));
  const [awsSpring, awsApi] = useSpring(() => ({
    from: { opacity: 0 },
  }));
  const [mongoSpring, mongoApi] = useSpring(() => ({
    from: { opacity: 0 },
  }));
  const [sqlSpring, sqlApi] = useSpring(() => ({
    from: { opacity: 0 },
  }));
  const [bulmaSpring, bulmaApi] = useSpring(() => ({
    from: { opacity: 0 },
  }));
  const [figmaSpring, figmaApi] = useSpring(() => ({
    from: { opacity: 0 },
  }));
  const [npmSpring, npmApi] = useSpring(() => ({
    from: { opacity: 0 },
  }));
  const [expressSpring, expressApi] = useSpring(() => ({
    from: { opacity: 0 },
  }));
  const [djangoSpring, djangoApi] = useSpring(() => ({
    from: { opacity: 0 },
  }));



  const animateTitle = useRef(null);
  const animateJS = useRef(null);
  const animateHTML = useRef(null);
  const animateCSS = useRef(null);
  const animatePython = useRef(null);
  const animateNode = useRef(null);
  const animateGit = useRef(null);
  const animateReact = useRef(null);
  const animateAWS = useRef(null);
  const animateMongo = useRef(null);
  const animateSQL = useRef(null);
  const animateBulma = useRef(null);
  const animateFigma = useRef(null);
  const animateNPM = useRef(null);
  const animateExpress = useRef(null);
  const animateDjango = useRef(null);

  useEffect(() => {
    function animate(element, animation, thresh) {
      const options = {
        root: null,
        rootMargin: "0px",
        threshold: thresh,
        once: true,
      };

      const observer = new IntersectionObserver((entries) => {
        const target = entries[0];
        if (target.isIntersecting) {
          animation();
        }
      }, options);

      if (element.current) {
        observer.observe(element.current);
      }
    }
    animate(animateTitle, titleSlide, 0.1);
    animate(animateJS, jsShrink, 0.7);
    animate(animateHTML, htmlShrink, 0.7);
    animate(animateCSS, cssShrink, 0.7);
    animate(animatePython, pythonShrink, 0.7);
    animate(animateNode, nodeShrink, 0.7);
    animate(animateGit, gitShrink, 0.7);
    animate(animateReact, reactShrink, 0.7);
    animate(animateAWS, awsShrink, 0.7);
    animate(animateMongo, mongoShrink, 0.7);
    animate(animateSQL, sqlShrink, 0.7);
    animate(animateBulma, bulmaShrink, 0.7);
    animate(animateFigma, figmaShrink, 0.7);
    animate(animateNPM, npmShrink, 0.7);
    animate(animateExpress, expressShrink, 0.7);
    animate(animateDjango, djangoShrink, 0.7);
  });

  const titleSlide = () => {
    titleApi.start({
      from: {
        x: 300
      },
      to: {
        x: 0
      },
    });
  };
  const jsShrink = () => {
    jsApi.start({
      from: {
        opacity: 0
      },
      to: {
        opacity: 1
      },
    });
  };
  const htmlShrink = () => {
    htmlApi.start({
      from: {
        opacity: 0
      },
      to: {
        opacity: 1
      },
    });
  };
  const cssShrink = () => {
    cssApi.start({
      from: {
        opacity: 0
      },
      to: {
        opacity: 1
      },
    });
  };
  const pythonShrink = () => {
    pythonApi.start({
      from: {
        opacity: 0
      },
      to: {
        opacity: 1
      },
    });
  };
  const nodeShrink = () => {
    nodeApi.start({
      from: {
        opacity: 0
      },
      to: {
        opacity: 1
      },
    });
  };
  const gitShrink = () => {
    gitApi.start({
      from: {
        opacity: 0
      },
      to: {
        opacity: 1
      },
    });
  };
  const reactShrink = () => {
    reactApi.start({
      from: {
        opacity: 0
      },
      to: {
        opacity: 1
      },
    });
  };
  const awsShrink = () => {
    awsApi.start({
      from: {
        opacity: 0
      },
      to: {
        opacity: 1
      },
    });
  };
  const mongoShrink = () => {
    mongoApi.start({
      from: {
        opacity: 0
      },
      to: {
        opacity: 1
      },
    });
  };
  const sqlShrink = () => {
    sqlApi.start({
      from: {
        opacity: 0
      },
      to: {
        opacity: 1
      },
    });
  };
  const bulmaShrink = () => {
    bulmaApi.start({
      from: {
        opacity: 0
      },
      to: {
        opacity: 1
      },
    });
  };
  const figmaShrink = () => {
    figmaApi.start({
      from: {
        opacity: 0
      },
      to: {
        opacity: 1
      },
    });
  };
  const npmShrink = () => {
    npmApi.start({
      from: {
        opacity: 0
      },
      to: {
        opacity: 1
      },
    });
  };
  const expressShrink = () => {
    expressApi.start({
      from: {
        opacity: 0
      },
      to: {
        opacity: 1
      },
    });
  };
  const djangoShrink = () => {
    djangoApi.start({
      from: {
        opacity: 0
      },
      to: {
        opacity: 1
      },
    });
  };

  return(
    <>
      <main className='main-bg' id='skills'>
        <section className='skills-section'>
          <animated.h1 className='skills-title' ref={animateTitle} style={{...titleSprings}}>My Skills</animated.h1>
          <section className="icons">
            <animated.i className="devicon-javascript-plain" ref={animateJS} style={{...jsSpring}}><p className="icon-text">JavaScript</p></animated.i>
            <animated.i className="devicon-html5-plain" ref={animateHTML} style={{...htmlSpring}}><p className="icon-text">HTML</p></animated.i>
            <animated.i className="devicon-css3-plain" ref={animateCSS} style={{...cssSpring}}><p className="icon-text">CSS</p></animated.i>
            <animated.i className="devicon-python-plain" ref={animatePython} style={{...pythonSpring}}><p className="icon-text">Python</p></animated.i>
            <animated.i className="devicon-nodejs-plain" ref={animateNode} style={{...nodeSpring}}><p className="icon-text">Node.js</p></animated.i>
            <animated.i className="devicon-git-plain" ref={animateGit} style={{...gitSpring}}><p className="icon-text">Git</p></animated.i>
            <animated.i className="devicon-react-original" ref={animateReact} style={{...reactSpring}}><p className="icon-text">React</p></animated.i>
            <animated.i className="fa-brands fa-aws" ref={animateAWS} style={{...awsSpring}}><p className="icon-text">AWS S3</p></animated.i>
            <animated.i className="devicon-mongodb-plain" ref={animateMongo} style={{...mongoSpring}}><p className="icon-text">MongoDB</p></animated.i>
            <animated.i className="devicon-postgresql-plain" ref={animateSQL} style={{...sqlSpring}}><p className="icon-text">PostgreSQL</p></animated.i>
            <animated.i className="devicon-bulma-plain" ref={animateBulma} style={{...bulmaSpring}}><p className="icon-text">Bulma</p></animated.i>
            <animated.i className="devicon-figma-plain" ref={animateFigma} style={{...figmaSpring}}><p className="icon-text">Figma</p></animated.i>
            <animated.i className="devicon-npm-original-wordmark" ref={animateNPM} style={{...npmSpring}}><p className="icon-text">npm</p></animated.i>
            <animated.i className="devicon-express-original" ref={animateExpress} style={{...expressSpring}}><p className="icon-text">Express</p></animated.i>
            <animated.i className="devicon-django-plain" ref={animateDjango} style={{...djangoSpring}}><p className="icon-text">Django</p></animated.i>
          </section>
        </section>
      </main>
    </>
  )
}