import Content from "../../components/Content/Content.jsx";
import Experience from "../../components/Experience/Experience.jsx";
import NavBar from "../../components/NavBar/NavBar.jsx";
import Welcome from "../../components/Welcome/Welcome.jsx";
import Skills from "../../components/skills/Skills.jsx";




export default function HomePage() {

  const scrollToWelcome = () => {
    const welcomeElement = document.getElementById("welcome");
    welcomeElement.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToContent = () => {
    const contentElement = document.getElementById("content");
    contentElement.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToSkills = () => {
    const skillsElement = document.getElementById("skills");
    skillsElement.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToExperience = () => {
    const experienceElement = document.getElementById("experience");
    experienceElement.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <>
    <main>
      <NavBar
        scrollToWelcome={scrollToWelcome}
        scrollToContent={scrollToContent}
        scrollToSkills={scrollToSkills}
        scrollToExperience={scrollToExperience}
      />
      <div className="scroll-align" >
        <Welcome id="welcome" />
        <Content id="content" />
        <Experience id="experience" />
        <Skills id="skills" />
      </div>
    </main>
    </>
  );
}
