import './Welcome.css'
import React, { useEffect, useRef } from "react";
import { useSpring, animated } from '@react-spring/web'
import { useState } from 'react';




export default function Welcome() {

  const [h1Springs, h1Api] = useSpring(() => ({
    from: { x: 0, opacity: 0 },
  }));

  const [titleSprings, titleApi] = useSpring(() => ({
    from: { opacity: 0 },
  }));

  const [githubSprings, githubApi] = useSpring(() => ({
    from: { x: -100 },
  }));

  const [linkedinSprings, linkedinApi] = useSpring(() => ({
    from: { x: 200 },
  }));

  const [emailSprings, emailApi] = useSpring(() => ({
    from: { opacity: 0, y: 70 },
  }));


  const animateh1 = useRef(null);
  const animateTitle = useRef(null);
  const animateGithub = useRef(null);
  const animateLinkedin = useRef(null);
  const animateEmail = useRef(null);


  useEffect(() => {
    function animate(element, animation) {
      const options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.2,
        once: true,
      };

      const observer = new IntersectionObserver((entries) => {
        const target = entries[0];
        if (target.isIntersecting) {
          animation();
        }
      }, options);

      if (element.current) {
        observer.observe(element.current);
      }
    }
    animate(animateh1, h1Slide)
    animate(animateTitle, titleFade)
    animate(animateGithub, githubSlide)
    animate(animateLinkedin, linkedinSlide)
    animate(animateEmail, emailSlide)
  });

  const h1Slide = () => {
    h1Api.start({
      from: {
        x: -300, opacity: 0
      },
      to: {
        x: 0, opacity: 1
      },
    });
  };

  const titleFade = () => {
    titleApi.start({
      from: {
        opacity: 0
      },
      to: {
        opacity: 1
      },
    });
  };

  const githubSlide = () => {
    githubApi.start({
      from: {
        x: -100
      },
      to: {
        x: 0
      },
    });
  };

  const linkedinSlide = () => {
    linkedinApi.start({
      from: {
        x: 200
      },
      to: {
        x: 0
      },
    });
  };

  const emailSlide = () => {
    emailApi.start({
      from: {
        opacity: 0,
        y: 70
      },
      to: {
        opacity: 1,
        y: 0
      },
    });
  };

  const [copy, setCopy] = useState(false)

  const handleCopyToClipboard = async () => {
    const copyText = document.getElementById("emailAddress");
    copyText.select()
    copyText.setSelectionRange(0, 99999);
    await navigator.clipboard.writeText(copyText.value)
    setCopy(true)
  };

  return(
    <>
      <main className='main-bg' id='welcome'>
        <section className='welcome-section'>
          <h4 className='hi'>Hi, I am</h4>
          <animated.h1 ref={animateh1} className='name' style={{...h1Springs}}>Fin Garrard.</animated.h1>
          <animated.h1 className='title' ref={animateTitle} style={{...titleSprings}}>Software Engineer.</animated.h1>
          <animated.p className='statement' ref={animateTitle} style={{...titleSprings}}>
              I am a Software Engineer with a strong academic background and a deep passion for sports and the outdoors.Throughout my life I have been a keen sportsman, having played a variety of sports at a high level and am an avid Arsenal fan. My sports career has allowed me to foster a mentality that thrives in teams and I enjoy helping people realise their potential. Having completed my degree I decided it was the right time to pursue something that has been my passion since I was little. As a person, I am focused and driven and love the challenge of learning new topics. My experience at university has helped me develop a highly analytical thought process and have come to love finding solutions to complex problems. I pride myself on my ability to communicate and work frictionlessly with my peers. Undertaking General Assembly's immersive Software Engineering course has further provided me with the skills to integrate seamlessly into the technology sector. 
          </animated.p>
          <section className="socials">
            <animated.a href='https://github.com/Fing1499' className='ghub-link' target="_blank" rel="noopener noreferrer" ref={animateGithub} style={{...githubSprings}}>
              <button className="button is-large is-responsive gbutton">
                <span className="icon is-medium">
                  <i className="fab fa-github"></i>
                </span>
                <span>GitHub</span>
              </button>
            </animated.a>
            <animated.a href="https://www.linkedin.com/in/fin-garrard/" className="linkedin" target="_blank" rel="noopener noreferrer" ref={animateLinkedin} style={{...linkedinSprings}}>
              <button class="button is-medium is-responsive inbutton">
                <span class="icon is-small">
                  <i class="fa-brands fa-linkedin-in fa-xl"></i>
                </span>
              </button>
            </animated.a>
          </section>
            <animated.button class="button is-medium is-responsive emailbutton" onClick={handleCopyToClipboard} ref={animateEmail} style={{...emailSprings}}>
              <span id='emailAddress'>findlaygarrard@gmail.com</span>
              <span class="icon">
                {
                copy ?
                <i class="fa-solid fa-check"></i>
                :
                <i class="fa-solid fa-copy"></i>
                }
              </span>
            </animated.button>
        </section>
      </main>
    </>
  )
}