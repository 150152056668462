import './Content.css'
import Qurate from '../Images/Qurate.png'
import Habito from '../Images/Habito.png'
import JobSeekrs from '../Images/JobSeekrs.png'
import Battleships from '../Images/Battleships.png'
import React, { useEffect, useRef } from "react";
import { useSpring, animated } from '@react-spring/web'

export default function Content() {


  const [oneSprings, oneApi] = useSpring(() => ({
    from: { x: 0 },
  }));

  const [twoSprings, twoApi] = useSpring(() => ({
    from: { x: 300 },
  }));
  
  const [threeSprings, threeApi] = useSpring(() => ({
    from: { x: 0 },
  }));

  const [fourSprings, fourApi] = useSpring(() => ({
    from: { x: 300 },
  }));

  const [titleSpring, titleApi] = useSpring(() => ({
    from: { opcacity: 0, scale: 0.5 },
  }))


  const animateOne = useRef(null);
  const animateTwo = useRef(null);
  const animateThree = useRef(null);
  const animateFour = useRef(null);
  const animateTitle = useRef(null)


  useEffect(() => {
    function animate(element, animation) {
      const options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.01,
        once: true
      };
  
      const observer = new IntersectionObserver((entries) => {
        const target = entries[0];
        if (target.isIntersecting) {
          animation();
        }
      }, options);
  
      if (element.current) {
        observer.observe(element.current);
      }
    }
    animate(animateOne, leftSlide);
    animate(animateTwo, rightSlide);
    animate(animateThree, leftSlide2);
    animate(animateFour, rightSlide2);
    animate(animateTitle, titlefade);
  });

  const leftSlide = () => {
    oneApi.start({
      from: {
        x: -300
      },
      to: {
        x: 1
      },
    });
  }
  const leftSlide2 = () => {
    threeApi.start({
      from: {
        x: -300
      },
      to: {
        x: 1
      },
    });
  };

  const rightSlide = () => {
    twoApi.start({
      from: {
        x: 300
      },
      to: {
        x: 0
      },
    });
  }
  const rightSlide2 = () => {
    fourApi.start({
      from: {
        x: 300
      },
      to: {
        x: 0
      },
    });
  };

  const titlefade = () => {
    titleApi.start({
      from: {
        opacity: 0, scale: 0.5
      },
      to: {
        opacity: 1, scale:1
      },
    })
  }

  return(
    <>
      <main className='main-bg' id='content'>
        <section className='projects-section'>
          <animated.h1 className='projects' ref={animateTitle} style={{...titleSpring}}>My Projects</animated.h1>
          <ul>
            <animated.li className='p1 project-li' ref={animateOne} style={{...oneSprings}}>
              <section className="project-links">
                <a href='https://habitoo-13f15a6250b8.herokuapp.com/' className='project-a' target="_blank" rel="noopener noreferrer"><h3 className='project-title'>Habito</h3><i class="fa-solid fa-arrow-up-right-from-square"></i></a>
                <a className='readme' href='https://github.com/Fing1499/Habito' target="_blank" rel="noopener noreferrer">ReadMe</a>
              </section>
              <p className='project-desc'>A habit and mood tracking application. Implements Reharts for data visualisation.</p>
              <section className="img-section">
                  <img src={Habito} alt="Habito" className="img" />
              </section>
              <ul className='built-with'>
                <li className='built-with-li'>React</li>
                <li className='built-with-li'>Express</li>
                <li className='built-with-li'>Three.js</li>
                <li className='built-with-li'>MongoDB</li>
              </ul>
            </animated.li>
            <animated.li className='p2 project-li' ref={animateTwo} style={{...twoSprings}}>
            <section className="project-links">
                <a href='https://qurate.fly.dev/' className='project-a' target="_blank" rel="noopener noreferrer"><h3 className='project-title'>Qurate</h3><i class="fa-solid fa-arrow-up-right-from-square"></i></a>
                <a className='readme' href='https://github.com/Fing1499/qurate' target="_blank" rel="noopener noreferrer">ReadMe</a>
              </section>
              <p className='project-desc'>An art based social media platform. Qurate has full user to user interaciton via likes, follows, messages and commments.</p>
              <section className="img-section">
                <img src={Qurate} alt="Habito" className="img" />
              </section>
              <ul className='built-with'>
                <li className='built-with-li'>Python</li>
                <li className='built-with-li'>Django</li>
                <li className='built-with-li'>PostgreSQL</li>
                <li className='built-with-li'>AWS S3</li>
              </ul>
            </animated.li>
            <animated.li className='p3 project-li' ref={animateThree} style={{...threeSprings}}>
              <section className="project-links">
                <a href='https://jobseekrs.fly.dev/' className='project-a' target="_blank" rel="noopener noreferrer"><h3 className='project-title'>JobSeekrs</h3><i class="fa-solid fa-arrow-up-right-from-square"></i></a>
                <a className='readme' href='https://github.com/Fing1499/JobSeekrs' target="_blank" rel="noopener noreferrer">ReadMe</a>
              </section>
              <p className='project-desc'>A job appliction tracking platform. Users can upload and track their applications. Users can review their experience with a company.</p>
              <section className="img-section">
                <img src={JobSeekrs} alt="Habito" className="img" />
              </section>
              <ul className='built-with'>
                <li className='built-with-li'>Node.js</li>
                <li className='built-with-li'>Express</li>
                <li className='built-with-li'>MongoDB</li>
                <li className='built-with-li'>0Auth2</li>
              </ul>          
            </animated.li>
            <animated.li className='p4 project-li' ref={animateFour} style={{...fourSprings}}>
              <section className="project-links">
                <a href='https://fing1499.github.io/Battleships-Browser-Game/' className='project-a' target="_blank" rel="noopener noreferrer"><h3 className='project-title'>Battleships</h3><i class="fa-solid fa-arrow-up-right-from-square"></i></a>
                <a className='readme' href='https://github.com/Fing1499/Battleships-Browser-Game' target="_blank" rel="noopener noreferrer">ReadMe</a>
              </section>
              <p className='project-desc'>An online version of the classic board game. Implements AI ship placement and shot choice.</p>
              <section className="img-section">
                <img src={Battleships} alt="Habito" className="img" />
              </section>
              <ul className='built-with'>
                <li className='built-with-li'>JavaScript</li>
                <li className='built-with-li'>HTML</li>
                <li className='built-with-li'>CSS</li>
              </ul>          
            </animated.li>
          </ul>
        </section>
      </main>
    </>
  )
}