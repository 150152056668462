import './NavBar.css'
import { useState } from "react";


export default function NavBar({ scrollToWelcome, scrollToContent,  scrollToExperience, scrollToSkills  }) {

  const [activeButton, setActiveButton] = useState(null);

  function whichButton(buttonName) {
    setActiveButton(buttonName);
  }

  return (
    <>
      <section className='navbar-top'>
        <div className="fin-logo">
          <p>Fin Garrard</p>
        </div>
        <div className="nav-linkz">
          <button
            onClick={() => { scrollToWelcome(); whichButton('about'); }}
            className={activeButton === 'about' ? 'active' : ''}
          >
            About
          </button>
          <button
            onClick={() => { scrollToContent(); whichButton('projects'); }}
            className={activeButton === 'projects' ? 'active' : ''}
          >
            Projects
          </button>
          <button
            onClick={() => { scrollToExperience(); whichButton('experience'); }}
            className={activeButton === 'experience' ? 'active' : ''}
          >
            Experience
          </button>
          <button
            onClick={() => { scrollToSkills(); whichButton('skills'); }}
            className={activeButton === 'skills' ? 'active' : ''}
          >
            Skills
          </button>
        </div>
      </section>
    </>
  );
}